<template>
  <DetailTemplate
    :custom-class="'costing-detail detail-page'"
    v-if="getPermission('costing:view')"
  >
    <template v-slot:sidebar-detail>
      <v-card class="h-100" elevation="0">
        <div class="d-flex px-0 flex-wrap align-center">
          <template v-if="detail && detail.status_relation">
            <span class="my-1">
              <v-chip
                class="mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10 text-white"
                :color="detail?.status_relation?.color"
                label
              >
                {{ detail?.status_relation?.text }}
              </v-chip>
            </span>
          </template>

          <CustomStatus
            v-if="false"
            :status="detail.status"
            endpoint="costing/status"
          ></CustomStatus>

          <span class="font-weight-bold color-custom-blue font-size-21">{{
            formatMoney(detail?.grand_total)
          }}</span>
        </div>

        <div class="mt-5 px-0">
          <table width="100%">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Costing Information
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Costing #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <v-chip
                  label
                  color="chip-custom-blue"
                  outlined
                  class="text-white p-3"
                  small
                  >{{ detail.barcode }}
                </v-chip>
              </td>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Quotation #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <template v-if="detail?.quotation_relation?.id">
                  <router-link
                    :to="{
                      name: 'admin.quotation.detail',
                      params: { id: detail.quotation_relation.id },
                    }"
                  >
                    <v-chip
                      label
                      :color="
                        getQuotationStatusColor(
                          detail?.quotation_relation?.status
                        )
                      "
                      text-color=""
                      class="p-3 mb-1 pointer-events-none"
                      outlined
                      small
                      >{{ detail.quotation_relation.barcode }}
                    </v-chip>
                  </router-link>
                </template>
                <em v-else class="text-muted"> no quotation</em>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Rate opportunity
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <v-rating
                  readonly
                  dense
                  v-model.trim="detail.rating"
                  background-color="orange lighten-3"
                  color="orange"
                ></v-rating>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Report
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <ShowValue
                  :object="detail"
                  object-key="report"
                  label="Report"
                ></ShowValue>
              </td>
            </tr>
            <!--  <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Selling Currency
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <ShowValue
                  :object="detail"
                  object-key="selling_currency"
                  label="Selling Currency"
                ></ShowValue>
              </td>
            </tr> -->
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Customer Ref/Your Ref on PDF<!-- Customer Reference # -->
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <ShowValue
                  :object="detail"
                  object-key="reference"
                  label="Reference"
                ></ShowValue>
              </td>
            </tr>
            <tr v-if="detail.sale_by_relation">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Sales representatives
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0"
                  ><router-link
                    :to="{
                      name: 'admin.user.detail',
                      params: { id: detail?.sale_by_relation?.id },
                    }"
                    ><v-chip
                      v-if="
                        detail &&
                        detail.sale_by_relation &&
                        detail.sale_by_relation.display_name
                      "
                      small
                      class="mb-1 mr-1"
                    >
                      <v-avatar left small>
                        <img
                          v-if="
                            detail.sale_by_relation &&
                            detail.sale_by_relation.customeProfile_logo &&
                            detail.sale_by_relation.customeProfile_logo.file
                          "
                          :src="
                            detail.sale_by_relation.customeProfile_logo.file.url
                          "
                        />
                        <img
                          v-else
                          src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                        />
                      </v-avatar>
                      <span>{{ detail?.sale_by_relation?.display_name }}</span>
                    </v-chip></router-link
                  ></label
                >
              </td>
            </tr>
            <tr v-if="detail.prepared_user">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Prepared by
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0"
                  ><router-link
                    :to="{
                      name: 'admin.user.detail',
                      params: { id: detail?.prepared_user?.id },
                    }"
                  >
                    <v-chip
                      v-if="
                        detail &&
                        detail.prepared_user &&
                        detail.prepared_user.display_name
                      "
                      small
                      class="mb-1 mr-1"
                    >
                      <v-avatar left small>
                        <img
                          v-if="
                            detail.prepared_user &&
                            detail.prepared_user.profile_logo &&
                            detail.prepared_user.profile_logo.file
                          "
                          :src="detail.prepared_user.profile_logo.file.url"
                        />
                        <img
                          v-else
                          src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                        />
                      </v-avatar>
                      <span>{{ detail?.prepared_user?.display_name }}</span>
                    </v-chip>
                  </router-link></label
                >
              </td>
            </tr>
            <tr v-if="detail.added_at">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Created
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">
                  {{ formatDate(detail.added_at) }}
                </label>
              </td>
            </tr>
            <tr v-if="detail.validity_till && detail.validity_till > 0">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Validity
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">
                  {{ getValidityDate(detail.validity_till) }}
                  <span class="red--text">
                    ({{ detail.validity_till }}Days)
                  </span>
                </label>
              </td>
            </tr>
            <tr class="mt=1" v-if="detail?.tags?.length > 0">
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Tags
              </th>
            </tr>
            <tr v-if="detail?.tags?.length > 0">
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                colspan="2"
              >
                <template v-if="detail.tags && detail.tags.length">
                  <v-chip
                    v-for="(row, index) in detail.tags"
                    :key="index"
                    class="mr-1 mb-1"
                    text-color="white"
                    small
                    label
                    :color="row.color"
                  >
                    <span class="">
                      {{ row.text }}
                    </span>
                  </v-chip>
                </template>
              </td>
            </tr>
            <tr v-if="detail?.opportunity_relation?.id > 0" class="mt=1">
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Opportunity Information
              </th>
            </tr>
            <tr v-if="detail?.opportunity_relation?.id > 0">
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Opportunity #
              </td>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-500 text-capitalize"
              >
                <Barcode
                  color="red"
                  dense
                  small
                  :id="detail?.opportunity_relation?.id"
                  route="opportunity.detail"
                  :barcode="detail?.opportunity_relation?.barcode"
                ></Barcode>
              </td>
            </tr>
            <tr v-if="detail?.opportunity_relation?.id > 0">
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Opportunity Title
              </td>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-500 text-capitalize"
              >
                <ShowValue
                  :object="detail?.opportunity_relation"
                  object-key="opportunity_name"
                  label="Opportunity Title"
                ></ShowValue>
              </td>
            </tr>
            <tr v-if="detail?.project_relation?.id > 0" class="mt=1">
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Project Information
              </th>
            </tr>
            <tr v-if="detail?.project_relation?.id > 0">
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Project #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <Barcode
                  dense
                  small
                  color="red"
                  route="project.detail"
                  :barcode="detail?.project_relation?.barcode"
                  :id="detail?.project_relation?.id"
                ></Barcode>
              </td>
            </tr>
            <tr v-if="detail?.project_relation?.id > 0">
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Project Name
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <ShowValue
                  :object="detail?.project_relation"
                  object-key="name"
                  label="Project Title"
                ></ShowValue>
              </td>
            </tr>

            <tr v-if="detail?.approved_reject_remarks">
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Approved Remark
              </th>
            </tr>
            <tr v-if="detail?.approved_reject_remarks">
              <td
                valign="middle"
                colspan="2"
                class="py-1 font-size-18 font-weight-500"
              >
                {{ detail.approved_reject_remarks }}
              </td>
            </tr>
            <tr v-if="detail?.unapproved_remark">
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Unapproved Remark
              </th>
            </tr>
            <tr v-if="detail?.unapproved_remark">
              <td
                valign="middle"
                colspan="2"
                class="py-1 font-size-18 font-weight-500"
              >
                {{ detail.unapproved_remark }}
              </td>
            </tr>
          </table>
        </div>
      </v-card>
    </template>
    <template v-slot:header-title>
      <span
        class="text-h5 text-capitalize color-custom-blue font-weight-700 font-size-20 mr-2"
      >
        {{ detail.title }}
      </span>
    </template>
    <template v-slot:header-action>
      <div class="d-flex align-center justify-end">
        <div class="text-h5 font-weight-bold me-3">
          Selling Currency:
          <span class="red--text">
            <template v-if="detail?.selling_currency">
              {{ detail?.selling_currency }}
            </template>
            <template v-else>SGD</template>
          </span>
        </div>
        <template v-if="false && detail.status == 1">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_approved')"
              >
                <v-icon left>mdi-check</v-icon> Approve
              </v-btn>
            </template>
            <span>Click here to Approve</span>
          </v-tooltip>
        </template>
        <template v-if="false && detail.status == 1 && userDetails.role == 4">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="red"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_rejected')"
              >
                <v-icon left>mdi-close </v-icon> Reject
              </v-btn>
            </template>
            <span>Click here to Reject</span>
          </v-tooltip>
        </template>
        <template v-if="detail.status == 10 && !detail.is_revised">
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('revise')"
              >
                <v-icon left>mdi-update</v-icon> Revise
              </v-btn>
            </template>
            <span>Click here to revise Costing</span>
          </v-tooltip>
        </template>

        <!--  <template v-if="detail.status == 2 && !payment_in_milestones">
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('convert_invoice')"
              >
                Generate Invoice
              </v-btn>
            </template>
            <span>Generate Invoice</span>
          </v-tooltip>
        </template> -->
        <template v-if="detail.status == 5">
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="green"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_accepted')"
              >
                <v-icon left>mdi-check-decagram-outline</v-icon> Accept
              </v-btn>
            </template>
            <span>Mark As Accepted</span>
          </v-tooltip>
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="red"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_rejected')"
              >
                <v-icon left>mdi-close-thick</v-icon> Reject
              </v-btn>
            </template>
            <span>Mark As Rejected</span>
          </v-tooltip>
        </template>
        <template v-if="detail.status == 11">
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="green"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_approved')"
              >
                <v-icon left>mdi-check-decagram-outline</v-icon> Approve
              </v-btn>
            </template>
            <span>Mark As Approve</span>
          </v-tooltip>
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="red"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_unapproved')"
              >
                <v-icon left>mdi-close-thick</v-icon> Unapprove
              </v-btn>
            </template>
            <span>Mark As Unapprove</span>
          </v-tooltip>
        </template>
        <template v-if="detail.status == 1">
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('edit')"
              >
                <v-icon left>mdi-pencil</v-icon> Edit
              </v-btn>
            </template>
            <span>Click here to edit Costing</span>
          </v-tooltip>
        </template>
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
          left
          style="z-index: 99 !important"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              :disabled="statusLoading"
              class="mx-1 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="statusLoading || more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-btn
          depressed
          class="mx-1 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <!--  <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll quotation-detail-height"
        style="position: relative"
      > -->
      <v-container fluid>
        <!--  <v-row v-if="pageLoaded">
            <v-col md="12"> -->
        <v-tabs
          active-class="custom-tab-active"
          v-model="costingTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/quotation.svg')" />
              <!--end::Svg Icon-->
            </span>
            Overview
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#line-item">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/line-item.svg')" />
              <!--end::Svg Icon-->
            </span>
            Line Items
          </v-tab>
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            href="#milestones"
            v-if="payment_in_milestones && false"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
              <!--end::Svg Icon-->
            </span>
            Milestones
          </v-tab>
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            href="#invoice"
            v-if="is_quotation_invoice_allow && false"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
              <!--end::Svg Icon-->
            </span>
            Invoices
          </v-tab>
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            href="#visit"
            v-if="false"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
              <!--end::Svg Icon-->
            </span>
            Visits
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#attachments">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/attachment.svg')" />
              <!--end::Svg Icon-->
            </span>
            Attachments
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#history">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            History
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="costingTab">
          <v-tab-item value="other">
            <v-container fluid>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-1">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >Location Detail</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <span class="color-custom-blue font-weight-700 font-size-19">
                    <router-link
                      :to="
                        getDefaultRoute('company.detail', {
                          params: {
                            id: detail?.customer?.id,
                          },
                        })
                      "
                      >{{ detail?.customer?.display_name }}
                    </router-link>
                  </span>
                  <v-layout class="my-4">
                    <v-flex md6 class="mr-2 custom-border-right">
                      <table width="100%">
                        <tr>
                          <td class="font-weight-600">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                            >
                              Site Location</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="py-0 font-size-18 font-weight-500">
                            <template>
                              <div
                                class="custom-flex-d"
                                v-if="detail?.property?.property_name"
                              >
                                <b>
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                </b>
                                <span
                                  >{{ detail?.property?.property_name }}<br
                                /></span>
                              </div>
                              <div v-else>
                                <em class="text-muted">
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                  no Site Name</em
                                >
                              </div>
                            </template>

                            <template>
                              <div class="custom-flex-d">
                                <b>
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-map-marker
                                  </v-icon>
                                </b>
                                <span>
                                  <template v-if="detail?.property?.unit_no"
                                    >{{ detail?.property?.unit_no }},</template
                                  >
                                  <template v-if="detail?.property?.street_1"
                                    >{{ detail?.property?.street_1 }},</template
                                  >
                                  {{ detail?.property?.street_2 }}
                                  <br />
                                  {{ detail?.property?.country }}
                                  <template
                                    v-if="
                                      detail?.property?.zip_code &&
                                      detail?.property?.zip_code != '000000'
                                    "
                                  >
                                    {{ detail?.property?.zip_code }}
                                  </template>
                                </span>
                              </div>
                            </template>

                            <!--  <a v-if="detail &&
                                detail.property &&
                                detail.property.property_address
                                " target="_blank"
                                                          :href="`https://maps.google.com/?q=${detail.property.property_address}`">{{
                                detail.property.property_address }}</a> -->
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600">Contact details</td>
                        </tr>
                        <tr
                          v-if="
                            detail?.property_contact_person &&
                            detail?.property_contact_person?.display_name
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail?.property_contact_person?.display_name }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            detail?.property_contact_person &&
                            detail?.property_contact_person?.primary_phone
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail?.property_contact_person?.primary_phone }}
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no phone number</em>
                        </tr>
                        <tr
                          v-if="
                            detail?.property_contact_person &&
                            detail?.property_contact_person?.primary_email
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail?.property_contact_person?.primary_email }}
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no email address</em>
                        </tr>
                      </table>
                    </v-flex>
                    <v-flex md6 class="ml-2">
                      <table width="100%">
                        <tr>
                          <td class="font-weight-600">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                            >
                              Billing Address</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="py-0 font-size-18 font-weight-500">
                            <template>
                              <div
                                class="custom-flex-d"
                                v-if="detail?.billing?.property_name"
                              >
                                <b>
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                </b>
                                <span
                                  >{{ detail?.billing?.property_name }}<br
                                /></span>
                              </div>
                              <div v-else>
                                <em class="text-muted">
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                  no Site Name</em
                                >
                              </div>
                            </template>

                            <template>
                              <div class="custom-flex-d">
                                <b>
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-map-marker
                                  </v-icon>
                                </b>
                                <span>
                                  <template v-if="detail?.billing?.unit_no"
                                    >{{ detail?.billing?.unit_no }},</template
                                  >
                                  <template v-if="detail?.billing?.street_1"
                                    >{{ detail?.billing?.street_1 }},</template
                                  >
                                  {{ detail?.billing?.street_2 }}
                                  <br />
                                  {{ detail?.billing?.country }}
                                  <template
                                    v-if="
                                      detail?.billing?.zip_code &&
                                      detail?.billing?.zip_code != '000000'
                                    "
                                  >
                                    {{ detail?.billing?.zip_code }}
                                  </template>
                                </span>
                              </div>
                            </template>

                            <!--  <a v-if="detail &&
                            detail.billing &&
                            detail.billing.property_address
                            " target="_blank"
                                                      :href="`https://maps.google.com/?q=${detail.billing.property_address}`">{{
                            detail.billing.property_address }}</a> -->
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600">Contact details</td>
                        </tr>
                        <tr
                          v-if="
                            detail?.billing_contact_person &&
                            detail?.billing_contact_person?.display_name
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail?.billing_contact_person?.display_name }}
                          </td>
                        </tr>

                        <tr
                          v-if="
                            detail?.billing_contact_person &&
                            detail?.billing_contact_person?.primary_phone
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail?.billing_contact_person?.primary_phone }}
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no phone number</em>
                        </tr>
                        <tr
                          v-if="
                            detail?.billing_contact_person &&
                            detail?.billing_contact_person?.primary_email
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail?.billing_contact_person?.primary_email }}
                            <!-- <v-icon
                          v-if="detail.billing_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        > -->
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no email address</em>
                        </tr>
                      </table>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-1">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >Notes</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <v-layout class="mb-4">
                    <v-flex md12 class="custom-border-right">
                      <v-card-title class="headline grey lighten-4 py-1">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Internal Note
                        </span>
                      </v-card-title>
                      <read-more
                        class="mx-4 mt-1 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          detail?.admin_note || '<em>no Internal Note</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-flex>
                    <!--   <v-flex md6>
                      <v-card-title class="headline grey lighten-4 py-1">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Client Note
                        </span>
                      </v-card-title>

                      <read-more
                        class="mx-4 mt-1 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          detail.client_remark || '<em>No Client Note </em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-flex> -->
                  </v-layout>
                </v-card-text>
              </v-card>

              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-1">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >TERMS &amp; CONDITIONS</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <div
                    class="w-100 custom-grey-border p-4 mb-2"
                    v-if="
                      detail &&
                      detail.term_condition_id &&
                      detail.term_conditions_title &&
                      detail.term_conditions_title.label
                    "
                  >
                    {{ detail.term_conditions_title.label }}
                  </div>
                  <div class="w-100 custom-grey-border p-4">
                    <read-more
                      class="mx-2 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                      more-str="read more"
                      :text="
                        detail?.term_conditions ||
                        '<em>No Terms &amp; Conditions</em>'
                      "
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </div>
                </v-card-text>
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-1">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >PAYMENT TERMS</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <!--  <div
                    class="w-100 custom-grey-border p-4 mb-2"
                    v-if="
                      detail &&
                      detail.term_condition_id &&
                      detail.term_conditions_title &&
                      detail.term_conditions_title.label
                    "
                  >
                    {{ detail.term_conditions_title.label }}
                  </div> -->
                  <div class="w-100 custom-grey-border p-4">
                    <read-more
                      class="mx-2 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                      more-str="read more"
                      :text="
                        detail?.payment_terms || '<em>No PAYMENT TERMS</em>'
                      "
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="custom-grey-border remove-border-radius mb-4"
                v-if="detail.status == 2 || detail.status == 3"
              >
                <v-card-title class="headline grey lighten-4">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >{{ get_remark_title() }}</span
                  >
                </v-card-title>
                <v-card-text
                  class="p-6 font-size-16"
                  v-if="detail.status == 2 || detail.status == 3"
                >
                  <div class="w-100 custom-grey-border p-4">
                    <read-more
                      class=""
                      v-if="detail && detail.general_remark"
                      more-str="read more"
                      :text="
                        detail.general_remark ||
                        `<em>No ${get_remark_title()}</em>`
                      "
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </div>
                </v-card-text>
              </v-card>
            </v-container>
          </v-tab-item>
          <v-tab-item value="line-item">
            <!--   <pre>{{ detail }}</pre> -->
            <ViewLineItem is-costing />
          </v-tab-item>

          <v-tab-item value="attachments">
            <AdditionalDocuments
              :type-id="detail.id"
              class="mx-4"
              type-text="Costing Attachments"
              :type="20"
              :extensive="false"
            >
            </AdditionalDocuments>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <InternalHistoryDetail
              type="costing"
              :type_id="detail?.id"
            ></InternalHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <!--  </perfect-scrollbar> -->
      <SendMail
        :mail-dialog="mail_dialog"
        title="Send as Email"
        type="costing"
        v-on:close="mail_dialog = false"
        :type-id="costingId"
        v-on:success="getCosting()"
      ></SendMail>
      <Dialog :common-dialog="confirm_dialog" :dialog-width="dialogWidth">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p
                class="font-weight-600 font-size-19"
                v-if="confirm_type != 'mark_as_send'"
              >
                Are you sure, you want to
                <span class="font-weight-700">{{ get_confirm_title() }}</span
                >?
              </p>
              <p v-else class="font-weight-600 font-size-19">
                Are you sure you have
                <span class="font-weight-700"
                  >send out the costing..... already to customer</span
                >?
              </p>
              <v-layout
                class="my-4"
                v-if="
                  confirm_type == 'mark_as_approved' ||
                  confirm_type == 'mark_as_rejected' ||
                  confirm_type == 'mark_as_accepted' ||
                  confirm_type == 'mark_as_unapproved'
                "
              >
                <v-flex>
                  <label class="font-weight-500 font-size-16 required"
                    >Remark</label
                  >
                  <TextAreaField
                    :rules="[
                      validateRules.required(actionData.remark, 'Remark'),
                    ]"
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    v-model="actionData.remark"
                    placeholder="Remark"
                    solo
                    flat
                    row-height="25"
                    counter="200"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                class="mt-4"
                v-if="
                  confirm_type == 'mark_as_accepted' ||
                  confirm_type == 'mark_as_rejected' ||
                  confirm_type == 'mark_as_approved' ||
                  confirm_type == 'mark_as_unapproved'
                "
              >
                <v-flex md12>
                  <label class="font-weight-500 font-size-16">Attachment</label>

                  <file-upload v-model="actionData.attachments" :maxLimit="5">
                  </file-upload>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="confirm_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            v-on:click="confirm_submit()"
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>
<script>
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
/* import ViewLineItem from "@/view/pages/partials/Line-Item-Detail-New"; */
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import SendMail from "@/view/pages/partials/Send-Mail";
import Barcode from "@/view/pages/partials/Barcode.vue";

import {
  GET,
  /* QUERY, */ PATCH,
  /* PUT, */
} from "@/core/services/store/request.module";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import JwtService from "@/core/services/jwt.service";
import Dialog from "@/view/pages/partials/Dialog";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ViewLineItem from "@/view/components/ViewLineItem.vue";
import FileUpload from "@/view/components/app-component/FileInput";
import moment from "moment-timezone";
/* import { PaginationEventBus } from "@/core/lib/pagination.lib"; */
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import {
  SET_VIEW_CALCULATION,
  SET_VIEW_LINE_ITEM,
} from "@/core/services/store/line.item.v2.module";
import ShowValue from "@/view/components/ShowValue";

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      formLoading: false,
      is_quotation_invoice_allow: false,
      tab: null,
      costingId: 0,
      pageLoaded: false,
      statusLoading: false,
      mail_dialog: false,
      invoiceLoading: false,
      deleteQuotationDialog: false,
      pageLoading: false,
      todayDate: null,
      DatePicker: false,
      deleteQuotationLoading: false,
      ValidDatePicker: false,
      signature: 0,
      confirm_loading: false,
      visit_count_dialog: false,
      download_template: "HYC_Pn_Inst",
      download_dialog: false,
      confirm_dialog: false,
      download_date: moment().format("YYYY-MM-DD"),
      //valid_till: moment().format("YYYY-MM-DD"),
      valid_till: moment().add(14, "days").format("YYYY-MM-DD"),
      confirm_type: null,
      /*   costingTab: "other", */
      payment_in_milestones: false,
      moreActions: [],
      detail: {},
      confirm: false,

      actionData: {
        remark: null,
        attachments: [],
        status: null,
      },
      attachments: [],
      profeinstaservice: {
        profe_insta_service: null,
        quotation: null,
      },
    };
  },
  methods: {
    getDueDay(day) {
      if (day == 2) {
        return 15;
      } else if (day == 3) {
        return 30;
      } else if (day == 4) {
        return 45;
      }
    },
    updateAttachment(param) {
      this.attachments = param;
    },
    lengthCount(param) {
      this.itemCount = param;
    },

    pageReload() {
      window.location.reload();
    },

    getValidityDate(days) {
      if (this.detail.added_at) {
        let date = moment(this.detail.added_at, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );
        let validity = moment(date, "YYYY-MM-DD")
          .add(days, "days")
          .format("YYYY-MM-DD");
        return this.formatDate(validity);
      }
    },
    refressData() {
      this.getCosting();
    },
    get_confirm_title() {
      if (this.confirm_type == "mark_as_closed") {
        return "Mark as Lost";
      } else if (this.confirm_type == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirm_type == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirm_type == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirm_type == "mark_as_accepted") {
        return "Mark as Accepted";
      } else if (this.confirm_type == "mark_as_approved") {
        return "Mark as Approve";
      } else if (this.confirm_type == "mark_as_send") {
        return "Send Quotation";
      } else if (this.confirm_type == "submited_for_Approver") {
        return "Submit For Approval";
      } else if (this.confirm_type == "mark_as_unapproved") {
        return "Unapprove";
      }
    },
    getColor(data) {
      if (data == "Open") {
        return "blue";
      } else if (data == "In-Progress") {
        return "yellow";
      } else if (data == "Hold") {
        return "red";
      } else if (data == "Completed") {
        return "green";
      } else if (data == "Cancelled") {
        return "red";
      } else if (data == "Hold & Unassigned") {
        return "cyan";
      }
    },
    getColorType(data) {
      if (data == 1) {
        return "green darken-3";
      } else if (data == 2) {
        return "orange darken-3";
      } else if (data == 3) {
        return "red darken-3";
      }
    },
    getColorText(data) {
      if (data == 1) {
        return "New";
      } else if (data == 2) {
        return "Maintenance";
      } else if (data == 3) {
        return "General";
      }
    },
    routeToVisit(id) {
      this.$router.push({
        name: "admin.visit.detail",
        params: { id },
      });
    },

    confirm_submit() {
      const _this = this;
      const validateStatus = _this.$refs.statusForm.validate();
      const formErrors = _this.validateForm(_this.$refs.statusForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }
      if (!validateStatus) {
        return false;
      }
      _this.confirm_loading = true;
      switch (this.confirm_type) {
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_send":
          this.updateStatus("mark_as_send");
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
        case "mark_as_unapproved":
          this.updateStatus("mark_as_unapproved");
          break;
        case "submited_for_Approver":
          this.updateStatus("submited_for_Approver");
          break;
      }
    },

    getCostingSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/quotation" })
        .then(({ data }) => {
          _this.signature = data.signature.value;
          _this.payment_in_milestones = data.payment_in_milestones;
          _this.is_quotation_invoice_allow = data.is_quotation_invoice_allow;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateMoreAction(type) {
      switch (type) {
        case "edit":
          this.editCosting();
          break;
        case "revise":
          this.reviseQuotation();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "re_quote":
          this.requoteQuotation();
          break;
        case "mark_as_closed":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_closed";
          break;
        case "mark_as_cancelled":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_cancelled";
          break;
        case "send_as_email":
          this.mail_dialog = true;
          break;

        case "mark_as_pending":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_pending";
          break;
        case "mark_as_rejected":
          this.actionData.remark = null;
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_rejected";
          break;
        case "mark_as_accepted":
          this.actionData.remark = null;
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_accepted";
          break;
        case "mark_as_send":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_send";
          break;
        case "submited_for_Approver":
          this.actionData.remark = null;
          this.confirm_dialog = true;
          this.confirm_type = "submited_for_Approver";
          break;
        case "mark_as_approved":
          this.actionData.remark = null;
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_approved";
          break;
        case "mark_as_unapproved":
          this.actionData.remark = null;
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_unapproved";
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    getSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: "quotation/" + this.costingId + "/signature-link",
        })
        .then(({ data }) => {
          const { signature_link } = data;
          window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    updateStatus(status) {
      if (this.statusLoading) {
        return false;
      }
      /*   this.actionData.attachments = this.attachments; */
      this.actionData.status = status;
      this.statusLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "costing/" + this.costingId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getCosting();
          this.confirm_loading = false;
          this.confirm_dialog = false;
          this.confirm_type = null;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.statusLoading = false;
          this.confirm_loading = false;
        });
    },

    downloadPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}costing-v1/${this.costingId}/pdf?&token=${token}&action=download`;
      window.open(url, "_blank");
    },
    printPDF() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${id}/print?token=${token}`;
      window.open(url, "_blank");
    },
    routeToJob() {
      if (this.detail.ticket_multiple_count > 1) {
        this.visit_count_dialog = true;
        if (this.confirm == false) {
          return false;
        }
      }
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            quotation: this.costingId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToInvoice() {
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: { quotation: this.detail.id },
        })
      );
    },
    routeToDuplicate() {
      this.$router.push(
        this.getDefaultRoute("costing.create", {
          query: { duplicate: this.costingId },
        })
      );
    },
    reviseQuotation() {
      this.$router.push(
        this.getDefaultRoute("costing.create", {
          query: { revise: this.costingId },
        })
      );
    },
    requoteQuotation() {
      this.$router.push(
        this.getDefaultRoute("costing.create", {
          query: { requote: this.costingId },
        })
      );
    },

    editCosting() {
      this.$router.push(
        this.getDefaultRoute("costing.create", {
          query: { edit: this.$route?.params?.id },
        })
      );
    },
    getCosting() {
      this.$store
        .dispatch(GET, { url: "costing-v1/" + this.costingId })
        .then(({ data }) => {
          this.detail = data;
          /*  console.log(this.detail); */
          this.moreActions = data.more_actions;

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Costing", route: "costing" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
          this.$store.dispatch(SET_VIEW_LINE_ITEM, this.detail?.items);
          this.$store.dispatch(SET_VIEW_CALCULATION, {
            total_selling: this.detail?.total_selling,
            total_cost_value: this.detail?.total_cost_value,
            total_margin: this.detail?.total_margin,
            discount_value: this.detail?.discount_value,
            discount_type: this.lodash.toSafeInteger(
              this.detail?.discount_type
            ),
            main_discount: this.detail?.main_discount,
            lump_discount_value: this.detail?.lump_discount_value,
            lump_discount_type: this.lodash.toSafeInteger(
              this.detail?.lump_discount_type
            ),
            lump_sum_discount: this.detail?.lump_sum_discount,
            discounted_total_selling: this.detail?.discounted_total_selling,
            grand_total: this.detail?.grand_total,
          });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },
    get_remark_title() {
      if (this.detail.status == 2) {
        return "Accepted Remark";
      } else if (this.detail.status == 3) {
        return "Rejected Remark";
      } else {
        return null;
      }
    },
  },
  created() {
    this.costingId = this.lodash.toSafeInteger(this.$route.params.id);
  },
  mounted() {
    if (this.costingId) {
      this.getCosting();
    }
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
    costingTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.costingTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "other";
      },
    },
  },
  components: {
    DetailTemplate,
    SendMail,
    Dialog,
    AdditionalDocuments,
    ShowValue,
    "file-upload": FileUpload,
    TextAreaField,
    ViewLineItem,
    InternalHistoryDetail,
    Barcode,
    CustomStatus,
  },
};
</script>
